import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { SimpleReward } from '../types/domain';
import { isRewardAvailable } from './isRewardAvailable';

interface SortRewardsParams {
  flowAPI: ControllerFlowAPI;
  rewards: SimpleReward[];
  pointsBalance: number;
}

export function sortRewards({ flowAPI, rewards, pointsBalance }: SortRewardsParams): SimpleReward[] {
  return rewards.slice().sort((first, second) => {
    const isFirstRewardAvailable = isRewardAvailable({ requiredPoints: first.requiredPoints, pointsBalance });
    const isSecondRewardAvailable = isRewardAvailable({ requiredPoints: second.requiredPoints, pointsBalance });

    // Sort by availability
    if (isFirstRewardAvailable && !isSecondRewardAvailable) {
      return -1;
    }

    if (!isFirstRewardAvailable && isSecondRewardAvailable) {
      return 1;
    }

    // Sort by activation
    if (first.couponActivated && !second.couponActivated) {
      return -1;
    }

    if (!first.couponActivated && second.couponActivated) {
      return 1;
    }

    // Sort by type
    if (first.type === RewardType.COUPON_REWARD && second.type === RewardType.DISCOUNT_AMOUNT) {
      return 1;
    }

    if (first.type === RewardType.DISCOUNT_AMOUNT && second.type === RewardType.COUPON_REWARD) {
      return -1;
    }

    // Sort by date created
    if (first.dateCreated && second.dateCreated) {
      return second.dateCreated.getTime() - first.dateCreated.getTime();
    }

    return 0;
  });
}
