import { Coupon } from '@wix/ambassador-ecommerce-coupons-v2-coupon/types';
import {
  CouponStatusStatus as ReferralCouponStatus,
  DiscountType as ReferralCouponType,
  ReferralReward,
} from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { LoyaltyCoupon, Status as LoyaltyCouponStatus } from '@wix/ambassador-loyalty-v1-coupon/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { RewardOrigin, SimpleReward } from '../../types/domain';
import { ClaimedTitleBuildFn } from '../create-claimed-coupon-title-builder';
import { BuildRewardDescriptionParams } from '../create-reward-description-builder';

interface Params {
  flowAPI: ControllerFlowAPI;
  rewards: Reward[];
  claimedCoupons: LoyaltyCoupon[];
  referralCouponRewards: ReferralReward[];
  couponEntityNames: Record<string, string>;
  buildRewardDescription: (params: BuildRewardDescriptionParams) => string;
  buildClaimedCouponTitle: ClaimedTitleBuildFn;
}

export const toSimpleRewards = ({
  flowAPI,
  rewards,
  claimedCoupons,
  referralCouponRewards,
  couponEntityNames,
  buildRewardDescription,
  buildClaimedCouponTitle,
}: Params): SimpleReward[] => {
  const { t } = flowAPI.translations;

  const simpleRewards = rewards.reduce<SimpleReward[]>((results, reward) => {
    if (!reward.active) {
      return results;
    }

    const configRewards: SimpleReward[] = [];
    const commonRewardParams = {
      id: reward.id,
      title: reward.name,
      type: reward.type,
      dateCreated: reward.createdDate ? new Date(reward.createdDate) : undefined,
      couponActivated: false,
      rewardOrigin: RewardOrigin.USER,
      isReferralReward: false,
    };

    if (reward.type === RewardType.DISCOUNT_AMOUNT) {
      reward.discountAmount?.configsByTier?.forEach(({ tierId, costInPoints }, index) => {
        configRewards.push({
          ...commonRewardParams,
          description: buildRewardDescription({ reward, configIndex: index }),
          requiredPoints: costInPoints ?? 0,
          tierId,
        });
      });
    } else if (reward.type === RewardType.COUPON_REWARD) {
      const { couponReward } = reward;
      const configsByTier =
        couponReward?.percentage?.configsByTier ??
        couponReward?.fixedAmount?.configsByTier ??
        couponReward?.freeShipping?.configsByTier;

      configsByTier?.forEach(({ tierId, costInPoints }, index) => {
        configRewards.push({
          ...commonRewardParams,
          description: buildRewardDescription({ reward, configIndex: index }),
          requiredPoints: costInPoints ?? 0,
          tierId,
        });
      });
    }

    return [...results, ...configRewards];
  }, []);

  if (claimedCoupons.length) {
    simpleRewards.push(
      ...claimedCoupons
        .filter((coupon) => coupon.status === LoyaltyCouponStatus.ACTIVE)
        .map<SimpleReward>((coupon) => ({
          id: coupon.id,
          title: buildClaimedCouponTitle({
            rewardName: coupon.rewardName!,
            coupon: coupon.couponReference!,
          }),
          description: t('app.reward-description.coupon-claimed', {
            code: coupon.couponReference?.code,
          }),
          type: RewardType.COUPON_REWARD,
          dateCreated: coupon.createdDate ? new Date(coupon.createdDate) : undefined,
          couponActivated: true,
          couponCode: coupon.couponReference?.code,
          couponName: coupon.rewardName,
          rewardOrigin: RewardOrigin.MEMBER,
          isReferralReward: false,
        })),
    );
  }

  if (referralCouponRewards.length) {
    simpleRewards.push(
      ...referralCouponRewards
        .filter(({ coupon }) => coupon?.status === ReferralCouponStatus.ACTIVE)
        .map<SimpleReward>(({ id, coupon, createdDate }) => {
          const { code, couponSpecification } = coupon!;
          const couponType = couponSpecification?.discountType;
          const entityName = couponEntityNames[coupon!.id!];

          const fakeCoupon: Coupon = {
            ...(entityName && {
              displayData: { name: entityName },
            }),
            specification: {
              scope: couponSpecification?.scope,
              minimumSubtotal: couponSpecification?.minimumSubtotal,
              moneyOffAmount:
                couponType === ReferralCouponType.FIXED_AMOUNT
                  ? couponSpecification?.fixedAmountOptions?.amount
                  : undefined,
              percentOffRate:
                couponType === ReferralCouponType.PERCENTAGE
                  ? couponSpecification?.percentageOptions?.percentage
                  : undefined,
              freeShipping: couponType === ReferralCouponType.FREE_SHIPPING,
              code,
              limitedToOneItem: couponSpecification?.limitedToOneItem,
              appliesToSubscriptions: couponSpecification?.appliesToSubscriptions,
              discountedCycleCount: couponSpecification?.discountedCycleCount,
            },
          };

          return {
            id,
            title: buildClaimedCouponTitle({
              rewardName: couponSpecification?.name ?? '',
              coupon: fakeCoupon,
            }),
            description: t('app.reward-description.coupon-claimed', {
              code,
            }),
            type: RewardType.COUPON_REWARD,
            dateCreated: createdDate ? new Date(createdDate) : undefined,
            couponActivated: true,
            couponCode: code,
            couponName: couponSpecification?.name,
            rewardOrigin: RewardOrigin.MEMBER,
            isReferralReward: true,
          };
        }),
    );
  }

  return simpleRewards;
};
